




































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { UserModule } from '@/store/modules/user'
import { instrumentApi } from '@/api/instrument'
import { departmentApi } from '@/api/department'
import { enterpriseApi, IGetEnterprisesParam } from '@/api/enterprise'
import { IUserParams, userApi } from '@/api/user'
import { groupApi } from '@/api/group'
import PERMISSION from '@/constants/permission'
import GenerateForm from '@/components/VueFormMaking/components/GenerateForm.vue'
import Pagination from '@/components/Pagination/index.vue'
import { log } from 'util'

@Component({ components: { GenerateForm, Pagination } })
export default class InstrumentSelect extends Vue {
  @Prop() value: any[] | undefined
  @Prop() type: string | undefined;
  @Prop() status: number | undefined;
  @Prop() processName: number | undefined;
  @Prop() custodianByMe: boolean | undefined
  @Prop() ignoreSearch: string[] | undefined
  @Prop() ignoreSelect: {[key: string]: any[]} | undefined

  condition: any = {};
  private params = { page: 1, perPage: 10 };
  private instruments = {} as any;
  private loading = false;
  private instrumentFields = [];
  private departments = {};
  private enterprises = {};
  users = {};
  groups = {};
  selected = [] as any[]

  selectable(row: any) {
    if (!this.ignoreSelect) {
      return true
    }
    for (const key in this.ignoreSelect) {
      if (this.ignoreSelect[key].indexOf(row[key]) !== -1) {
        return false
      }
    }
    return true
  }

  handleSelectionChange(row: any) {
    this.$emit('update:value', row)
    this.selected = row
  }

  toggleSelection(row: any) {
    const r = this.selected.find((item: any) => {return item.id === row.id})
    if (!r) {
      return
    }
    // @ts-ignore
    this.$refs.multipleTable.toggleRowSelection(r);
  }

  get formatterGroup() {
    // @ts-ignore
    return (row) =>
      // @ts-ignore
      this.groups?.items?.find((group) => group.id === row.deviceGroupId)?.name;
  }
  get showField() {
    const { user, isSuperAdmin }: any = UserModule;
    const isAdmin = user.manage_enterprise_ids?.includes(user.enterprise_id);
    return (key: string) => {
      return this.visibleColumns.includes(key) || isAdmin || isSuperAdmin;
    };
  }
  get isSuperAdmin(): boolean {
    return UserModule.isSuperAdmin;
  }
  get visibleColumns() {
    // @ts-ignore
    return UserModule.user.visible_columns;
  }
  get PERMISSION() {
    return PERMISSION;
  }
  get extraConfig() {
    return [
      {
        key: "enterpriseId",
        label: "企业",
        type: "select",
        // @ts-ignore
        options: this.enterprises?.items?.map((child) => ({
          value: child.id,
          label: child.name,
        })),
        placeholder: "请选择企业",
        hidden: !this.isSuperAdmin,
      },
      {
        key: "departmentId",
        label: "科室",
        type: "select",
        // @ts-ignore
        options: this.departments?.items?.map((child) => ({
          value: child.id,
          label: child.name,
        })),
        placeholder: "请选择科室",
        filterMethod:(q:string)=>this.getDepartments(q),
      },
      {
        key: "brand",
        label: "品牌",
        type: "input",
        placeholder: "请输入品牌",
      },
      {
        key: "status",
        label: "状态",
        type: "select",
        options: this.statusOptions,
        hidden: this.type,
        placeholder: "请选择状态",
      },
      {
        key: "custodianId",
        label: "保管人",
        type: "select",
        // @ts-ignore
        options: this.users?.items?.map((child) => ({
          value: child.id,
          label: child.name,
        })),
        placeholder: "请选择保管人",
        filterMethod:(q:string)=>this.getUsers(q),
      },
      {
        key: "deviceGroupId",
        label: "仪器组",
        type: "select",
        // @ts-ignore
        options: this.groups?.items?.map((child) => ({
          value: child.id,
          label: child.name,
        })),
        placeholder: "请选择仪器组",
        filterMethod:(q:string)=>this.getGroups(q),
      },
      {
        key: "bindEs",
        label: "绑定价签",
        type: "select",
        options: [
          {value: null, label: "全部"},
          {value: 0, label: "未绑定"},
          {value: 1, label: "已绑定"},
        ],
        placeholder: "是否绑定价签",
      },
    ].filter((item) => {return this.ignoreSearch?.indexOf(item.key) === -1});
  }

  get formatterLabel() {
    // @ts-ignore
    return (row) => {
      // @ts-ignore
      if (!this.config.noUseStatus) {
        return "";
      }
      const statusLabel = this.statusOptions?.find(
        // @ts-ignore
        ({ key }) => key === row.status
      )?.label;
      // @ts-ignore
      if (this.config?.noUseStatus?.includes(row.status)) {
        return `停用(${statusLabel})`;
      }
      // @ts-ignore
      return `${this.config?.in_tentative?.[row.inTentative]}(${statusLabel}）`;
    };
  }

  get statusIcon() {
    // @ts-ignore
    return (row) => {
      // @ts-ignore
      if (!this.config.noUseStatus) {
        return -2;
      }
      // @ts-ignore
      if (this.config?.noUseStatus?.includes(row.status)) {
        return -1;
      }
      if (row.inTentative) return 1;
      return 0;
    };
  }
  get statusOptions() {
    return (
      // @ts-ignore
      this.config?.instrumentStatus?.map((obj) => {
        const key = Number(Object.keys(obj)[0]);
        return {
          key,
          value: key,
          label: obj[key],
        };
      }) || []
    );
  }
  get config() {
    return UserModule.config;
  }
  onRefresh(condition: any) {
    this.condition = condition;
    this.getInstruments();
  }
  // @ts-ignore
  private getGroups(name:string) {
    groupApi
      .getGroups({
        name,
        // @ts-ignore
        page: 1,
        perPage: 99999,
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          // @ts-ignore
          : UserModule?.user?.enterprise?.id,
      })
      .then((res) => {
        this.groups = res;
      });
  }
  private async getInstruments() {
    this.loading = true;
    try {
      let condition = this.condition ? this.condition : {}
      if (this.custodianByMe) {
        condition["custodianId"] = UserModule.user.id
      }
      this.instruments = await instrumentApi.getInstruments({
        status: this.type ? this.status : undefined,
        ...this.params,
        ...condition,
      });
    } catch (error) {}
    this.loading = false;
  }
  // @ts-ignore
  private async getFields() {
    const [
      { value: sortRes = [] },
      { value: res = [] },
      { value: extraFields = [] },
    ]: any = await Promise.allSettled([
      enterpriseApi.getFieldsSort({
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          // @ts-ignore
          : UserModule?.user?.enterprise?.id,
      }),
      enterpriseApi.getFields({
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          // @ts-ignore
          : UserModule?.user?.enterprise?.id,
      }),
      instrumentApi.getInstrumentFields(),
    ]);

    const fields: any = [
      ...(sortRes?.length ? sortRes : []),
      ...res
        ?.reduce((s: any[], c: any) => {
          if (c instanceof Array) {
            return [...s, ...c];
          }
          return [...s, c];
        }, [])
        .filter(
          (child: any) =>
            !(sortRes?.length ? sortRes : [])?.find(
              (item: any) => child.key === item.key
            )
        ),
    ];
    this.instrumentFields = fields
      ?.filter(({ key }: any) => key !== "extend_info")
      .map((child: any) => {
        const field = extraFields.find((item: any) => item.id == child.key);
        if (field) {
          return {
            ...child,
            ...field,
          };
        }
        return child;
      });
  }
  private getDepartments(query: string) {
    departmentApi
      .getDepartments({
        name: query,
        page: 1,
        perPage: 99999,
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          // @ts-ignore
          : UserModule?.user?.enterprise?.id,
      })
      .then((data) => {
        this.departments = data;
      })
      .catch((e) => {
        this.$message.warning(`获取科室列表失败, ${e}`);
      });
  }

  private getEnterprises(query: string) {
    // @ts-ignore
    enterpriseApi
      .getEnterprises({
        name: query,
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          // @ts-ignore
          : UserModule?.user?.enterprise?.id,
      } as IGetEnterprisesParam)
      .then((data) => {
        this.enterprises = data;
      })
      .catch((e) => {
        this.$message.warning(`获取企业列表失败, ${e}`);
      });
  }

  private getUsers(query: string) {
    // @ts-ignore
    userApi
      .getUsers({
        name: query,
        page: 1,
        perPage: 99999,
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          // @ts-ignore
          : UserModule?.user?.enterprise?.id,
      } as IUserParams)
      .then((data) => {
        this.users = data;
      })
      .catch((e) => {
        this.$message.warning(`获取用户列表失败, ${e}`);
      });
  }

  private mounted() {
    this.getFields();
    this.getEnterprises("");
    this.getDepartments("");
    // this.getInstruments();
    this.getUsers("");
    // @ts-ignore
    this.getGroups();
  }
  handleSizeChange(size: number) {
    this.params.perPage = size;
    this.getInstruments();
  }

  handlePageChange(page: number) {
    this.params.page = page;
    this.getInstruments();
  }
}
