


















































import { Component, Vue, Prop, Ref } from 'vue-property-decorator'
import InstrumentEdit from '../InstrumentEdit.vue'
// @ts-ignore
import _ from 'lodash'
import { orderApi } from '@/api/order'
import { IUserParams, userApi } from '@/api/user'
import { UserModule } from '@/store/modules/user'
import { departmentApi, IDepartments } from '@/api/department'

@Component({ components: { InstrumentEdit } })
export default class InstrumentTmpSelect extends Vue {
  @Prop({ required: true }) value!: { [key: string]: { [key: string]: any } }
  @Prop({ required: false }) orderId!: number
  @Prop({ required: false }) disabled?: boolean
  @Ref('edit') insEdit!: any

  loading = false
  insList = [] as any[]
  insMap = [] as {id: any, name: any}[]
  departments = {} as IDepartments
  selectedInsList = [] as any[]
  orderDetail = {} as any
  approvalUsers = [] as any
  isApprovalUser = false
  updateRows = {} as any
  actionLoading = false
  openRows = {} as { [key: number]: boolean }

  async mounted() {
    this.loading = true
    await this.getOrderDetail()
    await this.checkCurrentUser()
    await this.initData()
    this.getDepartments()
    this.loading = false
  }

  get allIsPoint() {
    for (const data of this.insList) {
      if (!data.is_point) {
        return false
      }
    }
    return true
  }

  formatDepartmentName(id: number) {
    const dept = this.departments?.items?.find((dep) => {
      return dep.id == id
    })
    return dept?.name || '/'
  }

  selectable(row:any) {
    return !row?.is_point
  }

  private getDepartments() {
    departmentApi
      .getDepartments({
        name: '',
        page: 1,
        perPage: 99999,
        enterpriseId: UserModule.isSuperAdmin
          ? undefined
          // @ts-ignore
          : UserModule?.user?.enterprise?.id,
      })
      .then((data) => {
        this.departments = data
      })
      .catch((e) => {
      })
  }

  updateRow(row: any) {
    const insList = JSON.parse(JSON.stringify(this.insList))
    for (const key in row) {
      for (const idx in insList) {
        const ins = insList[idx]
        const webTmpId = Number(ins["webTmpId"])
        if (webTmpId === Number(key)) {
          insList[idx] = {...insList[idx], ...row[key]}
          break
        }
      }
    }
    this.insList = insList
    this.$forceUpdate()
  }

  formatToLine(value: string) {
    return value.replace(/([A-Z])/g, "_$1").toLowerCase();
  }
  updateSuccess(row: any) {
    // 驼峰
    const t = JSON.parse(JSON.stringify(row));
    // 下划线
    const l:any = {}
    const keys = Object.keys(row).map((key)=>{
      // 转下划线
      const k = this.formatToLine(key)
      l[k] = t[key]
      return k
    })
    this.insList.forEach((child) => {
      if (child.tmp_id === t.tmpId) {
        keys.forEach((k) => {
          if(['is_point','is_save'].includes(k) && child[k]) return;
          // 更新
          child[k] = l[k]
        });
      }
    });
    this.$forceUpdate()
  }

  async checkCurrentUser() {
    const currNode = this.orderDetail.current_nodes
    let taskDetail = {} as any
    for (const key in currNode) {
      if (currNode[key].hasOwnProperty('assignType')) {
        taskDetail = currNode[key]
        break
      }
    }
    if (taskDetail.assignType === 'person') {
      this.approvalUsers.push(...taskDetail.assignValue)
    } else if (taskDetail.assignType === 'role') {
      for (const roleId of taskDetail.assignValue) {
        const users = await userApi.getUsers({ roleId: roleId } as IUserParams)
        for (const user of users.items) {
          this.approvalUsers.push(user.id)
        }
      }
    }
    this.isApprovalUser = this.approvalUsers.indexOf(UserModule.user.id) !== -1
  }

  async initData() {
    const value = JSON.parse(JSON.stringify(this.value))
    for (const key in value) {
      value[key]['webTmpId'] = key
      value[key]['second_fix'] = value[key]['second_fix'] ? value[key]['second_fix'] : '否'
      value[key]['files'] = value[key]['files'] ? value[key]['files'] : []
      value[key]['report'] = value[key]['report'] ? value[key]['report'] : []
      this.insMap.push({id: key, name: value[key]["name"]})
      if (this.isApprovalUser || this.orderDetail.create_id === UserModule.user.id) {
        // 审批人能看到全部
        this.insList.push(value[key])
        continue
      }
      // 非审批人只能看到自己保管的
      if (value[key]['custodian_id'] === UserModule.user.id && value[key]['is_point'] === true) {
        this.insList.push(value[key])
      }
    }
  }

  async getOrderDetail() {
    if (!this.orderId) {
      return
    }
    this.orderDetail = await orderApi.getOrderDetail(this.orderId)
  }

  handleSelectionChange(rows: any) {
    this.selectedInsList = rows
  }

  handleExpandChange(row: any) {
    const tmpId = row.tmp_id
    this.openRows[tmpId] = !this.openRows[tmpId]
  }

  async save(data: any) {
    await this.getOrderDetail()
    const value = JSON.parse(JSON.stringify(this.orderDetail.form_data.form_data.ins_form))
    for (const key in data.formData) {
      value[key] = data.formData[key]
    }
    data.formData = value
    data['status'] = 7
    await orderApi.modifyOrder(data)
  }

  doAction(row?: any) {
    let data = {} as any
    let rows = this.selectedInsList
    if (row) {
      rows = [row]
    }
    for (const row of rows) {
      const id = Number(row['webTmpId'])
      if (isNaN(id)) {
        continue
      }
      data[id] = row
      for (const key in this.updateRows) {
        if (Number(key) === id) {
          data[id] = this.updateRows[key]
          break
        }
      }
    }
    const insCustodianIds = []
    for (const key in data) {
      if (data[key]['custodian_id']) {
        if (data[key]['custodian_id'] ===  UserModule.user.id) {
          data[key]['is_save'] = true
        }
        insCustodianIds.push(data[key]['custodian_id'])
      }
    }

    this.actionLoading = true
    // @ts-ignore
    orderApi.modifyOrder({
      id: this.orderId,
      status: 2,
      formData: data,
      // @ts-ignore
      pointPersonIds: insCustodianIds
    })
      .then(() => {
        this.$message.success('下发成功')
        for (const ins of this.insList) {
          if (data[ins['webTmpId']]) {
            ins['is_point'] = true
            if (ins["custodian_id"] == UserModule.user.id) {
              ins["is_save"] = true
            }
          }
        }
        this.actionLoading = false
      })
      .catch((e) => {
        this.$message.warning(`下发失败, ${e}`)
      })
      .finally(() => {
        this.actionLoading = false
      })
  }

  showDetail(row: any) {
    // @ts-ignore
    this.$refs.table.toggleRowExpansion(row)
  }

}
